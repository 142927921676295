/* globals $, dayjs, bootbox, bootstrap, Bloodhound */
import './common'

import './js/tagmanager'
import './js/country.dropdown'
import './js/advanced.search'

import './scss/city.scss'

(function () {

    $.validator.addMethod(
        "usernameRegex",
        function (value, element, regexp) {
            return this.optional(element) || regexp.test(value);
        },
        "Only letters, numbers or email addresses allowed."
    );

    $.validator.addMethod(
        "mobileRegex",
        function (value, element, regexp) {
            return this.optional(element) || regexp.test(value);
        },
        "Only numbers, spaces and dashes allowed."
    );

    $.validator.addMethod(
        "deleteRegex",
        function (value, element, regexp) {
            return this.optional(element) || regexp.test(value);
        },
        "Enter DELETE to proceed."
    );

    $('form:not(.no-validate)', this).each(function () {
        $(this).validate({
            errorPlacement: function (error, element) {
                error.appendTo(element.parent());
            },
            rules: {
                username: {
                    required: true,
                    usernameRegex: /^[\S]?[a-zA-Z0-9_.-]+[@]?[a-zA-Z0-9_.-]*$/
                },
                mobileNumber: {
                    required: false,
                    mobileRegex: /^[\d/\-\s]+$/
                },
                password: "required",
                confirmPassword: {
                    equalTo: "#password"
                },
                delete: {
                    required: true,
                    deleteRegex: /^DELETE$/i
                },
            }
        });
    });
})();

$(function () {
    $('.passwordField a').on('click', function () {
        const id = $(this).parent().find('.form-control').attr('id');
        const field = document.getElementById(id);
        if ($(this).hasClass('show')) {
            field.type = "text";
            $(this).removeClass('show');
            $(this).addClass('hide');
            $(this).html('<i class="fas fa-eye-slash"></i>');
        } else {
            field.type = "password";
            $(this).removeClass('hide');
            $(this).addClass('show');
            $(this).html('<i class="fas fa-eye"></i>');
        }
    });
});

$(function () {
    $('[data-bs-toggle="tooltip"]').tooltip();
    window.lazyLoadImages();
});

